import { colors, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${typography.type.dmSans};
`;

const Column = styled.div`
  margin-left: ${size.xs1}px;
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.27px;
  color: ${colors.gray5};
`;

const Content = styled.div`
  font-size: 0.889rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: 0.5px;
  color: ${colors.gray4};
`;

const IconWrapper = styled.div`
  color: #c6394c;
  margin: auto 0;
  margin-right: 12px;
`;

export {
  Container,
  Column,
  Title,
  Content,
  IconWrapper
};
